import { useEffect, useState } from "react";
import React from "react";
import "./GalleryImages.scss";
import TitleBarSelect from "../../components/TitleBarSelect/TitleBarSelect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { client } from "../../utils/contenful";
import GallerySlider from "./GallerySlider";
import BackToTop from "react-back-to-top-button";
import TopIcon from "../../images/Scroll/topIcon.png";

const GalleryImages = () => {
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [sliderLoading, setSlidersliderLoading] = useState(true);

  useEffect(() => {
    client
      .getEntries({ content_type: "galleryImages" })
      .then(function (entries) {
        entries.items.forEach(function (entry) {
          setCategories(
            Object.values(entry.fields).sort((a, b) =>
              a.id > b.id ? 1 : b.id > a.id ? -1 : 0
            )
          );
        });
        console.log(categories);
      });
  }, []);

  useEffect(() => {
    setSlidersliderLoading(true);
    if (categories.length !== 0) {
      console.log(categories);
      setCurrentCategory({
        ...categories[0],
        label: categories[0].category_title,
        value: categories[0].id,
      });
      setCurrentItem(categories[0].items[0]);
      setPageLoading(true);
    }
    setSlidersliderLoading(false);
  }, [categories]);

  const onItemChange = (item) => {
    setCurrentItem(item);
  };

  if (!pageLoading) {
    return (
      <div className="spinner">
        <div className="lds-dual-ring" />
      </div>
    );
  } else {
    return (
      <div className="general-page flex-column page">
        <TitleBarSelect
          title={currentCategory.category_title}
          defaultValue={currentCategory}
          options={categories.map((category) => ({
            ...category,
            label: category.category_title,
            value: category.id,
          }))}
          value={currentCategory}
          onChange={(value) => {
            setSlidersliderLoading(true);
            setCurrentCategory(value);
            setCurrentItem(value.items[0]);
            setSlidersliderLoading(false);
          }}
          addClass="top-category"
        />
        <div className="content">
          <BackToTop
            showOnScrollUp={false}
            showAt={300}
            speed={1500}
            easing="easeInOutQuint"
          >
            <img className="icon-top" src={TopIcon} alt="back to top" />
          </BackToTop>
          <div className="details">
            <TitleBarSelect
              title={currentCategory.category_title}
              defaultValue={currentCategory}
              options={categories.map((category) => ({
                ...category,
                label: category.category_title,
                value: category.id,
              }))}
              value={currentCategory}
              onChange={(value) => {
                setSlidersliderLoading(true);
                setCurrentCategory(value);
                setCurrentItem(value.items[0]);
                setSlidersliderLoading(false);
              }}
              addClass="left-category"
            />
            <h1 className="container-heading gallery">{currentItem.title}</h1>
            <p className="date-span">{currentItem.date}</p>
            <p className="date-span location">{currentItem.location}</p>
            <p className="container-body">{currentItem.description}</p>
          </div>
          <div className="photo slider-photos" key={currentCategory.id}>
            {sliderLoading ? (
              <div className="spinner">
                <div className="lds-dual-ring" />
              </div>
            ) : (
              <GallerySlider
                currentCategory={currentCategory}
                onItemChange={onItemChange}
                sliderLoading={sliderLoading}
                type="image"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default GalleryImages;
