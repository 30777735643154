import Select from "react-select";
import "./TitleBarSelect.scss";

const TitleBarSelect = ({
  title,
  options,
  defaultValue,
  onChange,
  value,
  addClass,
  ...rest
}) => {
  return (
    <div className={`title-bar-select ${addClass}`}>
      <Select
        options={options}
        className="category-select"
        classNamePrefix="category-select"
        defaultValue={defaultValue}
        onChange={onChange}
        value={value}
        {...rest}
      />
    </div>
  );
};

export default TitleBarSelect;
