import React, { useRef } from "react";
import Slider from "react-slick";
import "./Books.scss";
import ReactPlayer from "react-player/youtube";
import poetryBooks from "./BookTypes";
import { useParams } from "react-router";
import BackToTop from "react-back-to-top-button";
import TopIcon from "../../images/Scroll/topIcon.png";

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const bookLinks = [
  { text: "Translation of this book in italian", url: "https:/google.com" },
  { text: "Translation of this book in italian", url: "https:/google.com" },
  { text: "Translation of this book in italian", url: "https:/google.com" },
  { text: "This book in italian", url: "https:/google.com" },
];

const Books = (props) => {
  let sliderRef = useRef();
  const params = useParams();

  return (
    <div className="general-page flex-column page">
      <div className="content">
        <BackToTop
          showOnScrollUp={false}
          showAt={300}
          speed={1500}
          easing="easeInOutQuint"
        >
          <img className="icon-top" src={TopIcon} alt="back to top" />
        </BackToTop>
        <div className="details">
          <h1 className="book-title">
            {poetryBooks[params.bookId - 1].bookName}
          </h1>
          {poetryBooks[params.bookId - 1].subText1 && (
            <p className="book-subheading">
              {poetryBooks[params.bookId - 1].subText1}
            </p>
          )}
          <p className="book-subheading mb-12">
            {poetryBooks[params.bookId - 1].subText2}
          </p>
          <p className="container-body">
            {poetryBooks[params.bookId - 1].body}
          </p>
          {/* <button className="donate-button">
            <b>BUY NOW</b>
          </button> */}
        </div>
        <div className="photo slider-photos">
          <Slider
            {...settings}
            ref={sliderRef}
            swipeToSlide={false}
            swipe={false}
          >
            {poetryBooks[params.bookId - 1].images.map((image, index) => (
              <React.Fragment key={index}>
                <img src={image} alt="temp" />
                <br />
                <br />
                <div className="image-count">
                  <button
                    className="prev-button"
                    disabled={index === 0}
                    onClick={() => {
                      sliderRef.current.slickPrev();
                    }}
                  />
                  <p>
                    Image {index + 1} /{" "}
                    {poetryBooks[params.bookId - 1].images.length}
                  </p>
                  <button
                    className="next-button"
                    disabled={
                      index + 1 === poetryBooks[params.bookId - 1].images.length
                    }
                    onClick={() => {
                      sliderRef.current.slickNext();
                    }}
                  />
                </div>
              </React.Fragment>
            ))}
          </Slider>
        </div>
      </div>
      <div className="content books">
        <div className="books-parent-div">
          <br />
          <p className="famous-heading margin-bottom">
            FAMOUS GAZAL FROM THE MAJMA
          </p>
          <div className="poetry">
            <img src={poetryBooks[params.bookId - 1].poemName} alt="poem" />
            <br />
            <hr className="divider" />
            <br />
            <img src={poetryBooks[params.bookId - 1].poemVerses} alt="verses" />
          </div>
          <br />
          {poetryBooks[params.bookId - 1].bookVideo && (
            <div>
              <p className="famous-heading margin-section">BOOK RECITING</p>
              <ReactPlayer
                url={poetryBooks[params.bookId - 1].bookVideo}
                controls={true}
                width="100%"
                className="react-player books"
              />
            </div>
          )}
          <br />
          {poetryBooks[params.bookId - 1].bookReview && (
            <div>
              <p className="famous-heading margin-section">BOOK REVIEW</p>
              {poetryBooks[params.bookId - 1].bookReview.map(
                (review, index) => (
                  <div className="book-reviews">
                    <img className="review-image" src={review} alt="review" />
                    <hr className="divider" />
                  </div>
                )
              )}
            </div>
          )}
          <br />
          <br />
          <br />
          {/* <p className="famous-heading margin-section">LIBRARY</p>
          <div className="links-wrapper">
            {bookLinks.map((link, index) => (
              <div className="each-link" key={index}>
                <li>{link.text}</li>
                &nbsp; &nbsp; &nbsp;
                <button
                  onClick={() => `${window.open(`${link.url}`, "_blank")}`}
                >
                  VIEW HERE
                </button>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Books;
