import Pride from "../../images/pridePicture.png";
import ImageEgg from "../../images/EggImageAwards.png";
import BackToTop from "react-back-to-top-button";
import TopIcon from "../../images/Scroll/topIcon.png";
import "./Awards.scss";

const Awards = () => {
  return (
    <div className="general-page flex-column page general-padding">
      <div className="content">
        <BackToTop
          showOnScrollUp={false}
          showAt={300}
          speed={1500}
          easing="easeInOutQuint"
        >
          <img className="icon-top" src={TopIcon} alt="back to top" />
        </BackToTop>
        <div className="details trust-details justify">
          <h1 className="container-heading trust">
            <span className="extra-color">AWARDS </span>
            <span>RECIEVED</span>
          </h1>
        </div>
        <div className="photo trust-egg">
          <div className="img-div">
            <img src={ImageEgg} alt="Parveen Logo" />
          </div>
        </div>
      </div>
      <div>
        <div className="content awards-mb-20">
          <div className="details justify">
            <h1 className="container-heading biography">1990</h1>
            <p className="container-heading biography biography-topic">
              PRIDE OF PERFORMANCE
            </p>
            <div className="trust-body biography">
              <p className="body-text biography">
                For her service in Urdu literature, at poetry in particular, she
                was awarded the Pride of Performace award by the Government of
                Pakistan.
              </p>
            </div>
          </div>
          <div className="photo biography">
            <img src={Pride} alt="test" />
          </div>
        </div>
      </div>
      <div className="trust-logo margin-awards-section">
        <p className="trust-logo-text">LIFE TIME AWARDS</p>
      </div>
      <div className="trust-body">
        <p className="body-text awards">
          <li>
            <b>1967-68</b> - <b>Certificate of Merit</b>, awarded by the Sir
            Syed Girls College, Karachi for Proficiency in Poetry
          </li>
          <li>
            <b>1969</b> - <b>Certificate of Merit</b>, awarded by the Students’
            Thinkers Forum, Karachi for securing First Prize in Essay
            Competition.
          </li>
          <li>
            <b>1970</b> - <b>Certificate of Merit</b>, awarded by Radio Pakistan
            Karachi, Students Drama Festival for participation in the Drama
            Festival.
          </li>
          <li>
            <b>1970</b> - The <b>Best Poet Award</b> by the United States
            Information Service (USIS), Karachi
          </li>
          <li>
            <b>1978</b> - The <b>Adamjee Award</b> for Best Poetry for the book
            Kushboo in Karachi
          </li>
          <li>
            <b>1979</b> - Awarded a Gold Medal for the{" "}
            <b>Best Poet of the Year at the Silver Jubilee</b> celebrations of
            Sir Syed College for Women, Karachi
          </li>
          <li>
            <b>1981</b> - Second position in the{" "}
            <b>Central Superior Service </b> (CSS) Exam
          </li>
          <li>
            <b>1982 - Best Probationer’s Award</b>, Civil Services Academy,
            Lahore
          </li>
          <li>
            <b>1985</b> - Awarded the <b>Allama Iqbal Hijra Award</b> for the
            book Kud Kalami, Lahore
          </li>
          <li>
            <b>1986</b> - Awarded the <b>Zahoor Nazar Award</b> for Urdu Nazam,
            India
          </li>
          <li>
            <b>1987</b> - Selected as <b>Member of the Writers Delegation</b> by
            the Government of Pakistan to visit China
          </li>
          <li>
            <b>1988</b> - Graduate Award for <b>Best TV Compere</b> (Literary
            Programs), Lahore
          </li>
          <li>
            <b>1989 </b>- Awarded the <b>Faiz Ahmad Faiz International Award</b>{" "}
            for Poetry, by the World Urdu Conference, New Delhi, India
          </li>
          <li>
            <b>1989</b> - Represented Pakistan in the{" "}
            <b>4th South Asian Poetry Festival</b>, Dhaka ,Bangladesh
          </li>
          <li>
            <b>1990 </b>- Selected as <b>Fulbright Scholar-in-Residence</b>,
            Hartford Consortium for Higher Education, Hartford, Conn. USA.
            Teaching South Asian Literature at the Trinity College, Hartford.
          </li>
          <li>
            <b>1991</b> - Received the <b>Thomas Jefferson Fellowship</b> for
            Edward S. Mason Program, to study at the John F. Kennedy School of
            Government, Harvard University, Cambridge, Mass., USA
          </li>
          <li>
            <b>1991</b> - Award in recognition of great talent and work for Urdu
            Literature. Presented on the occasion of <b>“Jashan-e-Faraz”</b> ,
            by Khyber Society of USA, at Roosevelt Hotel, New York, USA
          </li>
          <li>
            <b>1993</b> - Recommended by her Department, Central Board of
            Revenue for conferment of <b>Sitara-e-Imtiaz</b>.
          </li>
        </p>
      </div>
      <div className="trust-logo">
        <p className="trust-logo-text margin-awards-section">
          POST HUMOUS AWARDS
        </p>
      </div>
      <div className="trust-body">
        <p className="body-text awards">
          <li>
            <b>1995</b> - <b>Nishan-e-Fazeelat</b> by the old students’ society
            of University of Karachi, UNIKARIANS
          </li>
          <li>
            <b>1995</b> - All Pakistan Newspaper Society,{" "}
            <b>APNS 12th Journalist Award</b>
            (13th Annual), Lahore
          </li>
          <li>
            <b>1997</b> - Shield of Recognition, by the{" "}
            <b>Karachi Women’s Peace Committee</b>, on International Women’s
            Day, 50 Years in Pakistan
          </li>
          <li>
            <b>1997</b> - <b>Golden Women of Pakistan Award</b>, by the Ladies
            Forum. Karachi
          </li>
          <li>
            <b>2004</b> - <b>Life Time Achievement Award</b> in the field of
            poetry and her untiring endeavors in bringing life to the Federal
            Capital, by the Takshila Margalla Festival Award, Islamabad
          </li>
        </p>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Awards;
