import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import "./Footer.scss";
import cn from "classnames";

const Footer = () => {
  const history = useHistory();
  const [displayDonate, setDisplayDonate] = useState(history.location.pathname);

  useEffect(() => {
    return history.listen((location) => {
      console.log(`You changed the page to: ${location.pathname}`);
      setDisplayDonate(location.pathname);
    });
  }, [history]);

  console.log(displayDonate);

  return (
    <div className="footer-div flex-column page">
      <div className="icons">
        <br />
        <div className="icons-wrapper">
          <div className="icons-container mb-32">
            <a
              href="https://www.facebook.com/PerveenShakir"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon fb-container"
            >
              <div className="icon-small icon-fb" />
            </a>
            <a
              href="https://twitter.com/Perveen_Shakir_"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <div className="icon-small icon-tw" />
            </a>
            <a
              href="https://www.youtube.com/channel/UC-m-hBWP8fgvLTfC8LzJEmQ"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <div className="icon-small icon-yt" />
            </a>
            <a
              href="https://www.instagram.com/perveen.shakir/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <div className="icon-small icon-ig" />
            </a>
          </div>
          {/* {displayDonate !== "/foundation" && (
            <button
              className="donate-button footer mb-32"
              onClick={() => history.push("/foundation")}
            >
              <b>DONATE</b>
            </button>
          )} */}
        </div>
      </div>
      <div className="icons-wrapper">
        <div className="icons-container email mb-32">
          <p className="caption email">hello@perveenshakirfoundation.com</p>
        </div>
        <p className="caption corporate">
          <span>&#169;</span>THE PERVEEN SHAKIR FOUNDATION
        </p>
      </div>
    </div>
  );
};

export default Footer;
