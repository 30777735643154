import Image1 from "../../images/Biography/pic 1.png";
import Image2 from "../../images/Biography/pic2.png";
import Image3 from "../../images/Biography/pic3.png";
import Image4 from "../../images/Biography/pic4.png";
import ImageEgg from "../../images/EggImageBiography.png";
import BackToTop from "react-back-to-top-button";
import TopIcon from "../../images/Scroll/topIcon.png";
import "./Biography.scss";

const Biography = () => {
  return (
    <div className="general-page flex-column page general-padding">
      <div className="content">
        <BackToTop
          showOnScrollUp={false}
          showAt={300}
          speed={1500}
          easing="easeInOutQuint"
        >
          <img className="icon-top" src={TopIcon} alt="back to top" />
        </BackToTop>
        <div className="details trust-details">
          <h1 className="container-heading trust">
            <span>PERVEEN SHAKIR</span>{" "}
            <span className="extra-color">LIFE</span>
          </h1>
        </div>
        <div className="photo trust-egg">
          <div className="img-div">
            <img src={ImageEgg} alt="Parveen Logo" />
          </div>
        </div>
      </div>
      <div className="biography-item odd">
        <div className="content awards-mb-20">
          <div className="details">
            <h1 className="container-heading biography">1952</h1>
            <span className="container-heading biography biography-topic">
              CHILDHOOD & EARLY LIFE
            </span>
            <div className="trust-body biography">
              <p className="body-text biography">
                Perveen Shakir was born in Karachi on November 24, 1952. Her
                parents came from Lahiria Sarai in the Darbhanga district of
                Bihar. Her parents called her “Para” out of addection. She was
                an adorable child and had a loving relation with her sister.
                Both sisters went to Islamia High School of Rizvia Colony and
                later on joined “Rizvia Girls Secondary School”. She proved
                herself as an outstanding child. She went to Sir Syed College
                and did her B.A. with Honours in English from Karachi
                University.
                <br />
                <br />
                Perveen Shakir started writing at a very young age. She wrote
                both prose and poetry, contributed columns in Urdu newspapers,
                and a few articles in English dailies. Initially, she wrote
                under the pen-name "Beena".
              </p>
            </div>
          </div>
          <div className="photo biography">
            <img src={Image1} alt="test" />
          </div>
        </div>
      </div>
      <div className="biography-item even">
        <div className="content awards-mb-20 ">
          <div className="details">
            <h1 className="container-heading biography">1968</h1>
            <span className="container-heading biography biography-topic">
              ACADEMIC LIFE & CAREER
            </span>
            <div className="trust-body biography">
              <p className="body-text biography">
                She received two undergraduate degrees, one in English
                literature and the other in linguistics. She obtained an MA
                degree in Public Administration from Harvard University.
                <br />
                <br />
                After teaching for nine years, she joined the Civil Service of
                Pakistan and worked in the customs department. In 1986, she was
                appointed second secretary of the Central Board of Revenue (now
                Federal Board of Revenue) in Islamabad, Pakistan.
              </p>
            </div>
          </div>
          <div className="photo biography">
            <img src={Image2} alt="test" />
          </div>
        </div>
      </div>
      <div className="biography-item odd">
        <div className="content awards-mb-20 ">
          <div className="details">
            <h1 className="container-heading biography">1976</h1>
            <span className="container-heading biography biography-topic">
              POETRY & FAME
            </span>
            <div className="trust-body biography">
              <p className="body-text biography">
                Perveen Shakir's poems are known for their in-depth exploration
                of sensitive topics rarely talked about, especially for women.
                She considered Ahmed Nadeem Qasmi her 'ustad' and used to called
                him 'Ammujaan'.
                <br />
                <br />
                Through her ghazals in particular, she continued to embody a
                feminine voice through grammatical choices, giving a voice to
                females and the female experience. Perveen Shakir's poetry was
                well-received, and after her untimely death she is now
                considered one of the best and "most prominent" modern poets
                Urdu language has ever produced. Shakir's first book, Khushboo,
                was awarded the Adamjee Literary Award in 1976. Later, she was
                awarded the Pride of Performance, one of Pakistan's highest
                honours in 1990.
              </p>
            </div>
          </div>
          <div className="photo biography">
            <img src={Image3} alt="test" />
          </div>
        </div>
      </div>
      <div className="biography-item even">
        <div className="content awards-mb-20 ">
          <div className="details">
            <h1 className="container-heading biography">1994</h1>
            <span className="container-heading biography biography-topic">
              PERSONAL LIFE
            </span>
            <div className="trust-body biography">
              <p className="body-text biography">
                Perveen Shakir married her cousin Dr. Syed Naseer Ali, with whom
                she had a son, Syed Murad Ali — but the marriage did not last
                long.
                <br />
                <br />
                On 26 December 1994, Perveen's car collided with a bus while she
                was on her way to work in Islamabad. The accident resulted in
                her death, a great loss to the Urdu poetry world. The road on
                which the accident took place is named after her as Perveen
                Shakir Road. Upon her death, the Perveen Shakir Trust was
                established by her close friend, Parveen Qadir Agha. The Perveen
                Shakir Trust organises a yearly function and gives out the
                "Aks-e-Khushboo" Award.
              </p>
            </div>
          </div>
          <div className="photo biography">
            <img src={Image4} alt="test" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Biography;
