import ImageEgg from "../../images/Egg shaped Image/Egg Shaped Image.png";
import TrustLogo from "../../images/Layer 1.png";
import BackToTop from "react-back-to-top-button";
import TopIcon from "../../images/Scroll/topIcon.png";
import "./Trust.scss";

const Trust = () => {
  return (
    <div className="general-page flex-column page general-padding">
      <div className="content">
        <BackToTop
          showOnScrollUp={false}
          showAt={300}
          speed={1500}
          easing="easeInOutQuint"
        >
          <img className="icon-top" src={TopIcon} alt="back to top" />
        </BackToTop>
        <div className="details trust-details">
          <h1 className="container-heading trust">
            <span>THE PERVEEN SHAKIR</span>{" "}
            <span className="extra-color">TRUST</span>
          </h1>
        </div>
        <div className="photo trust-egg">
          <div className="img-div">
            <img src={ImageEgg} alt="Parveen Logo" />
          </div>
        </div>
      </div>
      <div className="trust-logo mt-20p">
        <img src={TrustLogo} alt="Trust Logo" />
        <br />
        <br />
        <p className="trust-logo-text">MISSION & OBJECTIVES</p>
        <p className="trust-logo-body-text">By Mrs. Parveen Qadir Agha</p>
        <p className="trust-logo-bottom-text">Chairperson</p>
      </div>
      <div className="trust-body mt-24p">
        <p className="body-text">
          The Perveen Shakir Trust was formed in 1995 in memory of the famous
          poet, Perveen Shakir who died in a tragic car accident on 26th
          December 1994 at the prime age of 42. A death so sudden and so tragic
          left her friends and readers stunned and shattered. The buried her
          amidst tears in the rain and felt so helpless. They wanted to do
          something for her to remedy their loss and to fill in the vacuum
          created in their hearts by her tragic death. Hence the creation of
          this Trust in 1995.
        </p>
        <br />
        <br />
        <p className="body-text">
          <b>The objectives of the Trust fell in two categories, namely:</b>
          <li> To promote and protect Perveen’s literary works, and</li>
          <li>To provide for the upbringing of Murad</li>
          <br />
          <br />
          The first objectives meant the promotion, the protection and the
          preservation of not only her poetic works, but also her prose and
          other literary pursuits, on print or on media. This has been a
          tremendous task and is a continuous one till today, 25 years down the
          line.
          <br />
          <br />
          The second objective was to look after her minor son, Murad who was
          hardly 15 years old when his mother, who was bringing him up as a
          single mother passed away. The Trust took up this responsibility till
          his coming of age. However, we continue to take interest in his
          activities. By now a software engineer he has made his way on his own
          to the Silicon Valley, California, USA working with the Tesla company
          on their truck project. At the same time Murad also reciprocates.
          <br />
          <br />
          After a decade of running the Trust, we decided to expand our
          activities and formally amended the Trust Deed the objectives to
          promote Urdu poetry and Urdu Literature generally in any manner
          whatsoever, and to encourage present and prospective Urdu poets and
          writers.
          <br />
          <br />
          <b>
            In the fulfillment of its objectives the Trust carries on the
            following activities and events:{" "}
          </b>
          <li>
            The grant of the annual ‘Perveen Shakir “Aks-e-Khushboo” Award’ for
            the best poetry written by a young poet.
          </li>
          <li>
            {" "}
            The grant of the annual ‘Perveen Shakir “Aks-e-Khushboo” Award’ for
            the best fiction book.
          </li>
          <li>
            Grant of an Annual Gold Medal to a student of a different University
            every year, standing first in the M.A. English exam and/or M.A.
            Urdu.
          </li>
          <li>
            Publication of books under the banner of its publication house “Lafz
            Log”.{" "}
          </li>
          <li>Launch of books.</li>
          <li>Holding of Literature, and Poetry Festivals.</li>
          <li>
            Facilitation to scholars doing research work on Perveen,
            particularly M.A. thesis and PhDs.
          </li>
          <li>
            Facilitation to those desirous of translating her works into foreign
            languages.
          </li>{" "}
          <li>
            Publication of a Brochure on the occasion of the Annual Function
            every year.{" "}
          </li>
          <li>The holding of an Annual Function to commemorate her memory.</li>
          <br />
          Every year it is a unique event. Whether paying tributes to our
          legends, to individual literature giants, holding of literature
          festivals or celebrating Perveen Shakir’s books and memories in some
          different way every time. The entire credit for these ideas goes to
          the creative genius of Mazharul Islam, Life Member of the Trust.
          <br />
          <br />
          All our activities are conducted by our large body of Members
          consisting of professional writers, poets, then serving and retired
          officers of the Inland Revenue Service (Perveen Shakir belonging to
          the Customs Service), Educationalists and members of civil society.
          There were eight life Members who set up the Trust and it has now
          grown into a large body. Throughout the year they are active and work
          entirely on voluntary basis.
          <br />
          <br />
          The Trust does not have any funds of its own and relies on civil
          society and corporations for occasional financial support.
        </p>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Trust;
