import useWindowSize from "../../utils/useWindowSize";
import "./LandingPage.scss";
import logo from "../../images/Logo.png";
import logo2x from "../../images/logo2x.png";
import versus from "../../images/verse2x.png";
import signature from "../../images/signature.png";
import signature2x from "../../images/signature2x.png";
import Navbar from "../../components/NavBar/NavBar";
import { useEffect, useRef, useState } from "react";
import { getDeviceType } from "../../utils/helpers";

const LandingPage = () => {
  const { width, height } = useWindowSize();
  const [contentHeight, setContentHeight] = useState();
  const [coverClass, setCoverClass] = useState("");
  const logoRef = useRef(null);
  const verseRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (!width) {
      setCoverClass("");
    }
    if (width >= 1080) {
      setCoverClass("large");
    } else if (width >= 640 && width < 1080) {
      setCoverClass("medium");
    } else {
      setCoverClass("small");
    }
  }, [width]);

  useEffect(() => {
    const logoHeight = logoRef.current.clientHeight;
    const logoMargin = parseInt(
      window.getComputedStyle(logoRef.current).getPropertyValue("margin-bottom")
    );
    const verseHeight = verseRef.current.clientHeight;
    setContentHeight(logoHeight + logoMargin + verseHeight);
  }, [logoRef?.current?.clientHeight, verseRef?.current?.clientHeight]);

  const renderPosition = () => {
    let x = Math.max(width - 1440, -190);
    let y = Math.max(height - 1024, -120);
    if (width >= 1080 && width < 1440) {
      if (height < 1024) {
        return `calc(100% - ${x}px) calc(100% - ${y}px)`;
      } else {
        return `calc(100% - ${x}px) 100%`;
      }
    } else if (height < 1024) {
      if (width >= 1080 && width < 1440) {
        return `calc(100% - ${x}px) calc(100% - ${y}px)`;
      } else if (width < 1080) {
        return "100% 100%";
      } else {
        return `100% calc(100% - ${y}px)`;
      }
    } else {
      return "100% 100%";
    }
  };

  const pageClass =
    getDeviceType() === "mobile" && width > height
      ? "landing-page mobile"
      : "landing-page";

  return (
    <div
      className={pageClass}
      style={{
        height,
        minHeight:
          width < 1080
            ? contentHeight + (width < 640 ? 320 : 540)
            : contentRef?.current?.clientHeight,
      }}
    >
      <img
        className={`cover ${coverClass}`}
        alt="cover"
        style={{
          maxHeight: width < 1080 ? height - contentHeight - 50 : height + 70,
          minHeight: width < 1080 ? `${width < 640 ? 320 : 540}px` : "720px",
          objectPosition: renderPosition(),
        }}
      />
      <div className="content flex-column" style={{ height }} ref={contentRef}>
        <Navbar addClass="no-padding" logoRef={logoRef} />
        <div className="verse-container flex-column fl-1 mb-8">
          <img src={versus} className="versus" alt="versus" ref={verseRef} />
          <img
            src={signature}
            srcSet={`${signature2x} 2x`}
            className="signature"
            alt="versus"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
