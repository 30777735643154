import BackToTop from "react-back-to-top-button";
import TopIcon from "../../images/Scroll/topIcon.png";
import "./ContactUs.scss";

const ContactUs = () => {
  return (
    <div className="contact-us-page flex-column page">
      {/* <TitleBar title="Contact Us" /> */}
      <div className="content">
        <BackToTop
          showOnScrollUp={false}
          showAt={300}
          speed={1500}
          easing="easeInOutQuint"
        >
          <img className="icon-top" src={TopIcon} alt="back to top" />
        </BackToTop>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempor vitae,
          lectus lacus adipiscing velit. Dignissim dignissim massa ut feugiat
          leo ut. Non erat urna sed leo. Magna condimentum maecenas. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Tempor vitae, lectus
          lacus adipiscing velit. Dignissim dignissim massa ut feugiat leo ut.{" "}
        </p>
      </div>
      <div className="contact-footer icons flex mb-20">
        <a
          href="https://www.facebook.com/PerveenShakir"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon-large"
        >
          <div className="icon-large icon-fb" />
        </a>
        <a
          href="https://twitter.com/Perveen_Shakir_"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon-large"
        >
          <div className="icon-large icon-tw" />
        </a>
        <a
          href="https://www.youtube.com/channel/UC-m-hBWP8fgvLTfC8LzJEmQ"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon-large"
        >
          <div className="icon-large icon-yt" />
        </a>
        <a
          href="https://www.instagram.com/perveen.shakir/"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon-large"
        >
          <div className="icon-large icon-ig" />
        </a>
      </div>
    </div>
  );
};

export default ContactUs;
