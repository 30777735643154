import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import ReactPlayer from "react-player";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: false,
  initialSlide: 1,
};

const GallerySlider = ({ currentCategory, onItemChange, loading, type }) => {
  let sliderRef = useRef();

  const gotoNext = (image, index) => {
    console.log(image);
    onItemChange(image);

    sliderRef.current.slickNext();
  };

  const gotoPrev = (image, index) => {
    onItemChange(image);

    sliderRef.current.slickPrev();
  };

  useEffect(() => {
    if (currentCategory.length !== 0) {
      sliderRef.current.slickGoTo(0);
    }
  }, [currentCategory, sliderRef]);

  return (
    <Slider {...settings} ref={sliderRef} swipeToSlide={false} swipe={false}>
      {currentCategory.items.map((image, index) => (
        <React.Fragment key={index}>
          {type === "video" ? (
            <ReactPlayer
              url={image.url}
              controls={true}
              width="100%"
              className="react-player"
            />
          ) : (
            <img src={image.url} alt={image.title} />
          )}
          <br />
          <br />
          <div className="image-count">
            <button
              className="prev-button"
              disabled={index === 0}
              onClick={() =>
                gotoPrev(currentCategory.items[index - 1], index - 1)
              }
            />
            {type === "video" ? (
              <p>
                Video {index + 1} / {currentCategory.items.length}
              </p>
            ) : (
              <p>
                Image {index + 1} / {currentCategory.items.length}
              </p>
            )}
            <button
              className="next-button"
              disabled={index + 1 === currentCategory.items.length}
              onClick={() =>
                gotoNext(currentCategory.items[index + 1], index + 1)
              }
            />
          </div>
        </React.Fragment>
      ))}
    </Slider>
  );
};

export default GallerySlider;
