import "./NavBar.scss";
import logo from "../../images/Logo.png";
import logo2x from "../../images/logo2x.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useWindowSize from "../../utils/useWindowSize";

const NavBar = (props) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (menuIsOpen) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [menuIsOpen]);
  return (
    <header
      className={`nav-bar ${props.addClass} ${menuIsOpen ? "open" : "closed"}`}
    >
      <Link className={`${menuIsOpen ? "hideLogo" : "showLogo"}`} to="/">
        <img
          src={logo}
          srcSet={`${logo2x} 2x`}
          className="logo"
          alt="logo"
          ref={props.logoRef}
        />
      </Link>
      <div className="menu-logo-parent">
        <div className="menu-logo" onClick={() => setMenuIsOpen(!menuIsOpen)} />
      </div>
      <ul
        className={`menu-items ${menuIsOpen ? "open" : "closed"}`}
        style={{ maxHeight: height - 96 }}
      >
        <li className="nav__menu-item">
          <p>Books</p>
          <ul className="nav__submenu">
            <Link className="nav__submenu-item books" to="/books/Khushboo/1">
              Khushboo
            </Link>
            <Link className="nav__submenu-item books" to="/books/Sadberg/2">
              Sadberg
            </Link>
            <Link className="nav__submenu-item books" to="/books/KhudKalami/3">
              Khud Kalami
            </Link>
            <Link className="nav__submenu-item books" to="/books/Inkaar/4">
              Inkaar
            </Link>
            <Link className="nav__submenu-item books" to="/books/Mah-e-tamam/5">
              Mah-e-tamam
            </Link>
            <Link
              className="nav__submenu-item books"
              to="/books/Kaf-e-Aa'ina/6"
            >
              Kaf-e-Aa'ina
            </Link>
          </ul>
        </li>
        <li className="nav__menu-item ml-32">
          <p>About</p>
          <ul className="nav__submenu">
            <Link className="nav__submenu-item" to="/biography">
              Biography
            </Link>
            <Link className="nav__submenu-item" to="/awards">
              Awards
            </Link>
          </ul>
        </li>
        <li className="nav__menu-item ml-32">
          <p>Gallery</p>
          <ul className="nav__submenu">
            <Link className="nav__submenu-item" to="/gallery/images">
              Images
            </Link>
            <Link className="nav__submenu-item" to="/gallery/videos">
              Videos
            </Link>
          </ul>
        </li>
        <li className="nav__menu-item ml-32 collapsed-link">
          <Link to="/trust">Perveen Shakir Trust</Link>
        </li>
        {/* <li className="nav__menu-item ml-32 collapsed-link">
          <Link to="/foundation">Perveen Shakir Foundation</Link>
        </li> */}
      </ul>
    </header>
  );
};

export default NavBar;
