import Verses1 from "../../images/books/Khushboo/verse.png";
import FirstEditionCover1 from "../../images/books/Khushboo/1st edition bookcover.png";
import FrontCover1 from "../../images/books/Khushboo/bookcover - front.png";
import FirstPreface1 from "../../images/books/Khushboo/preface 1.png";
import SecondPreface1 from "../../images/books/Khushboo/preface 2.png";
import PoemName1 from "../../images/books/Khushboo/poem name.png";
import KhushbooReview1 from "../../images/books/Khushboo/review1.png";
import KhushbooReview2 from "../../images/books/Khushboo/review2.png";

import Verses2 from "../../images/books/Sadberg/verse.png";
import ThirdPreface2 from "../../images/books/Sadberg/preface 3.png";
import FrontCover2 from "../../images/books/Sadberg/bookcover - front.png";
import BackCover2 from "../../images/books/Sadberg/bookcover - back.png";
import FirstPreface2 from "../../images/books/Sadberg/preface 1.png";
import SecondPreface2 from "../../images/books/Sadberg/preface 2.png";
import PoemName2 from "../../images/books/Sadberg/poem name.png";
import SadbergReview1 from "../../images/books/Sadberg/review1.png";

import Verses3 from "../../images/books/Khud Kalami/verse.png";
import FrontCover3 from "../../images/books/Khud Kalami/bookcover - front.png";
import BackCover3 from "../../images/books/Khud Kalami/bookcover - back.png";
import FirstEditionCover3 from "../../images/books/Khud Kalami/1st edition bookcover.png";
import PoemName3 from "../../images/books/Khud Kalami/poem name.png";
import KhudKalamiReview1 from "../../images/books/Khud Kalami/review1.png";
import KhudKalamiReview2 from "../../images/books/Khud Kalami/review2.png";

import Verses4 from "../../images/books/Inkaar/verses.png";
import FrontCover4 from "../../images/books/Inkaar/bookcover - front.png";
import BackCover4 from "../../images/books/Inkaar/bookcover - back.png";
import PoemName4 from "../../images/books/Inkaar/poem name.png";
import InkaarReview1 from "../../images/books/Inkaar/review1.png";

import Verses5 from "../../images/books/mah e tamam/verse.png";
import Cover5 from "../../images/books/mah e tamam/book cover@2x.png";
import Preface5 from "../../images/books/mah e tamam/preface.png";
import PoemName5 from "../../images/books/mah e tamam/poem name@2x.png";

import Verses6 from "../../images/books/Kaf e Aina/verse.png";
import FirstEditionCover6 from "../../images/books/Kaf e Aina/1st edition book cover.png";
import FrontCover6 from "../../images/books/Kaf e Aina/book cover - front.png";
import BackCover6 from "../../images/books/Kaf e Aina/book cover - back.png";
import FirstPreface6 from "../../images/books/Kaf e Aina/preface 1.png";
import SecondPreface6 from "../../images/books/Kaf e Aina/preface 2.png";
import PoemName6 from "../../images/books/Kaf e Aina/poem name.png";

const poetryBooks = [
  {
    id: 1,
    bookName: "Khushboo",
    subText1: null,
    subText2: "Fragrance",
    body: `The youth get the maximum flavour from her first book "Khushboo". What an apt name given to that book almost prophetic as it still places a soothing hand on the hearts throbbing with love and undergoing the trauma of a separated beloved. The poetry in this book is reflective of a confident expression of love by a woman. It gives us a variety of modes of communication of life by an intelligent self-assured girl. The persistent quality of Parveen Shakir’s poetry is; despite giving everything in love there is a graceful sheet of self-respect ever present under all circumstances.`,
    images: [FrontCover1, FirstEditionCover1, FirstPreface1, SecondPreface1],
    buyLink: "",
    poemName: PoemName1,
    poemVerses: Verses1,
    bookVideo: "https://www.youtube.com/watch?v=cMtYSNQNRcU",
    bookReview: [KhushbooReview1, KhushbooReview2],
  },
  {
    id: 2,
    bookName: "Sadberg",
    subText1: null,
    subText2: "Marsh Marigold",
    body: `After Khushboo in 1981 the poetess went on another highest of expression in her book "Sad Barg".  In this book it seems as if the poetess within a short span of time has taken a leap into the arena of maturity, insightfulness by taking  love onto a more  broaden horizon where it is merged in the philosophy of life  and seems as they  are one and  the same. The book is a combination of ghazals and poems and heavily relies on  metaphors and similes. This book is a serious note on the complexities of life, separation, breakups yet it gives hope to live and takes the reader to another height of optimism.`,
    images: [
      FrontCover2,
      BackCover2,
      FirstPreface2,
      SecondPreface2,
      ThirdPreface2,
    ],
    buyLink: "",
    poemName: PoemName2,
    poemVerses: Verses2,

    bookVideo: "https://www.youtube.com/watch?v=pX5L3nSPkJY",
    bookReview: [SadbergReview1],
  },
  {
    id: 3,
    bookName: "Khud Kalami",
    subText1: null,
    subText2: "Soliloquy",
    body: `"Khud Kalami"  the third book published in 1990 is more intrusive as the poetess is going through an unending process of self-analysis. It seems as she has become over the period of time more inward looking and is going through a process of self-immersion.  In the poem titled Khud Kalami she mentions that wave length that draws a line between her and her surroundings. Khud Kalami seems an "aitikaf" in that defined wave length.`,
    images: [FrontCover3, BackCover3, FirstEditionCover3],
    buyLink: "",
    poemName: PoemName3,
    poemVerses: Verses3,
    bookVideo: "https://www.youtube.com/watch?v=jnzwTp0BSKg",
    bookReview: [KhudKalamiReview1, KhudKalamiReview2],
  },
  {
    id: 4,
    bookName: "Inkaar",
    subText1: null,
    subText2: "Refusal",
    body: `The journey started from Khushboo ends up with "Inkaar" wherein she doesn't give up the romanticism of Khushboo but she realizes the hard realities that surrounds love, life and world. Inkar seems a book well written in the incessant heat producing experiences of life but with a firm understanding coupled with variety of experience.`,
    images: [FrontCover4, BackCover4],
    buyLink: "",
    poemName: PoemName4,
    poemVerses: Verses4,
    bookVideo: "https://www.youtube.com/watch?v=g5iacSm4b5o",
    bookReview: [InkaarReview1],
  },
  {
    id: 5,
    bookName: "Mah-e-tamam",
    subText1: null,
    subText2: "Full Moon (Compilation of the four books)",
    body: "Perveen Shakir published Mah-e-Tamam containing her four poetry books in 1994, just a few months before her death. Many a head rolled on reading the title of this poetry collection. Many asked her why the “kuliyat” so early in life, many were amazed at the very title of the book. All these questions were answered on 26th December, 1994 when she left her family, friends and readers mourning her tragic death. The very title of the book depicts the “Full Moon” and there is no denying that she will continue to brighten the horizon of Urdu poetry not only in Pakistan but around the world wherever Urdu poetry is read.",
    images: [Cover5, Preface5],
    buyLink: "",
    poemName: PoemName5,
    poemVerses: Verses5,
    bookVideo: "https://www.youtube.com/watch?v=RNexvjdsaY4",
    bookReview: null,
  },

  {
    id: 6,
    bookName: "Kaf-e-Aa'ina",
    subText1: "The Mirror's Edge",
    subText2: "(Posthumous release compiling works from diaries)",
    body: "It is ironic that the manuscript of Kaf-e-Aaina, the 5th poetry collection of Perveen Shakir was with her when she met that tragic car accident in which we lost her. In Kaf-e-Aaina she continues to write with same passion on topics so close to her heart. However, one feeling that comes across while reading her poetry collection published posthumously is she has talked about death in quite a few of her couplets – does it indicate that she had a feeling that the end was near.",
    images: [
      FrontCover6,
      BackCover6,
      FirstEditionCover6,
      FirstPreface6,
      SecondPreface6,
    ],
    buyLink: "",
    poemName: PoemName6,
    poemVerses: Verses6,
    bookVideo: "https://www.youtube.com/watch?v=TVFb2W44Puo",
    bookReview: null,
  },
];

export default poetryBooks;
